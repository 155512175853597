@tailwind base;
@tailwind components;
@tailwind utilities;

/* Manually import design tokens for desktop */
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import url('./guru-desktop/tokens.css');

.container {
  min-height: 100vh;
  font-size: 1.2rem;
  position: relative;
  z-index: 0;
}

code {
  font-style: italic;
  color: cornflowerblue;
}

select {
  background-size: initial;
  background-image: none;
}

/* temporary */
.shadow-top {
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
}

#nprogress {
  pointer-events: none;

  .bar {
    background: #1ac7f1;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */

  .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #1ac7f1, 0 0 5px #1ac7f1;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0, -4px);
    -ms-transform: rotate(3deg) translate(0, -4px);
    transform: rotate(3deg) translate(0, -4px);
  }

  /* Remove these to get rid of the spinner */

  .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #1ac7f1;
    border-left-color: #1ac7f1;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.h-screen {
  height: 100vh;
}

.bg-login {
  background: #006ed3 url(/images/bg-full.png) no-repeat center center fixed;
  background-size: cover;
}

.text-red-google {
  color: rgb(231, 0, 0);
}

.cta-login__icon {
  width: 13px;
  height: 13px;
  margin-top: 2px;
}

.h-full--without-header {
  min-height: calc(100vh - 3.5rem);
}

.overlay-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-list {
  position: relative;
}

.card-list::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 360px;
  width: 100%;
  background-color: #deecfd;
}

@screen md {
  .card-list::before {
    height: 200px;
  }
}

@screen lg {
  .card-list::before {
    height: 140px;
  }
}

.default-container {
  @apply container;
}

.simple-wysiwyg-content {
  a {
    color: var(--color-blue-70);
  }

  &.widget-component {
    a {
      @apply underline underline-offset-auto;
    }

    p {
      @apply mb-0;
    }
  }

  img {
    @apply w-full h-auto max-w-full;
  }

  ul {
    @apply pl-4 list-disc;
  }

  ol {
    @apply pl-4 list-decimal;
  }

  p {
    @apply mb-3;
  }

  &--aksinyata {
    p:last-child {
      @apply mb-0;
    }

    ul,
    ol {
      @apply mb-2 list-outside;

      padding-left: 1.1rem;
    }

    li p {
      @apply mb-0;
    }
  }

  &.material-content p {
    @apply mb-3;
  }

  &.aksi-nyata p {
    @apply mb-2;
  }

  &.material-content li {
    @apply mb-2;
  }

  &.material-content a {
    @apply underline underline-offset-auto;

    color: #1d4ed8;
  }

  /* scoping for video-inspirasi page */

  &.video-inspirasi {
    p {
      @apply mb-4;
    }

    strong {
      font-weight: var(--font-weight-heading-sm);
      font-size: var(--size-font-heading-sm);
      line-height: var(--size-line-height-text-heading-sm);
      text-transform: capitalize;

      @apply block;
    }

    p strong {
      @apply -mb-3;
    }

    li,
    li p {
      @apply mb-0;
    }

    ul {
      @apply pl-7;
    }

    li {
      @apply text-subdued;
    }
  }

  &.cerita-praktik {
    a {
      @apply underline;
    }

    ul,
    ol {
      @apply pl-10;
    }

    p {
      @apply mb-0;
    }

    blockquote {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }
  }
}

.vertical-progress-indicator {
  position: relative;

  &::before {
    content: '';

    @apply absolute left-0 z-0 left-3 top-1/2;

    height: 100%;
    width: 1px;
    background-color: var(--color-border-subdued);
  }

  &:last-child::before {
    display: none;
  }

  &.sub-item::before {
    @apply top-0;
  }
}

.material-card-expanded {
  max-height: 999em;
}

.yt-ratio-wrapper {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
  }

  & > .react-player,
  & > .shimmer,
  & > iframe {
    @apply absolute top-0 left-0 w-full h-full;
  }
}

/** Post test **/
.bottom-bar-shadow {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.25);
}

.checkmark-indicator {
  &--current::after {
    @apply absolute top-0 left-0 w-full h-full border-2 rounded-full border-primary;

    content: '';
  }

  &::before {
    @apply absolute top-1/2 left-1/2 bg-gray-30;

    content: '';
    margin-left: 10px;
    margin-top: -1px;
    width: 50%;
    height: 2px;
    z-index: 0;
  }

  &--small::before {
    margin-left: 5px;
  }

  &:last-child::before {
    display: none;
  }
}

.posttest-indicator--current {
  &::after {
    @apply absolute top-0 left-0 w-full h-full border-2 rounded-full border-primary;

    content: '';
  }

  &::before {
    @apply absolute top-1/2 left-1/2 bg-gray-30;

    content: '';
    margin-left: 10px;
    margin-top: -1px;
    width: 50%;
    height: 2px;
    z-index: 0;
  }

  &:last-child::before {
    display: none;
  }
}

.pdf-viewer-container {
  @apply relative w-full min-h-screen bg-surface-subdued h-fit;
}

.pdf-viewer-wrapper {
  @apply relative w-full h-fit;
}

.pdf-viewer-page {
  @apply my-2;

  direction: ltr;
  position: relative;
  overflow: visible;
  background-clip: content-box;
  background-color: rgba(255, 255, 255, 1);
}

.ikm-community-thumbnail {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((49 / 52) * 100%);
  }

  &--empty::before {
    @apply bg-gray-50;
  }

  > .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.input-ikm-datepicker {
  font-family: inherit !important;
  width: 100%;
  border: none !important;

  .react-datepicker__ {
    &header {
      background-color: white;
    }

    &month-container {
      width: 100%;
    }

    &week,
    &day-names {
      display: flex;
      justify-content: space-around;
    }

    &navigation-icon {
      top: 4px;
    }

    &navigation {
      top: -6px;
    }

    &day--selected {
      @apply !bg-interactive-default;

      color: #fff !important;
    }

    &day--keyboard-selected:hover,
    &month-text--keyboard-selected:hover,
    &quarter-text--keyboard-selected:hover,
    &year-text--keyboard-selected:hover {
      @apply bg-interactive-default;
    }

    &day--keyboard-selected,
    &month-text--keyboard-selected,
    &quarter-text--keyboard-selected,
    &year-text--keyboard-selected {
      @apply bg-interactive-default;

      color: #fff !important;
    }

    &header {
      padding: 0;
      padding-bottom: 8px;
    }

    &day--weekend,
    &day-name:nth-last-of-type(-n + 2) {
      @apply text-critical;
    }

    &day--disabled {
      @apply line-through text-disabled;
    }

    &day--outside-month {
      @apply text-gray-30;

      font-weight: normal;
    }

    &navigation-icon::before {
      @apply text-gray-80;
    }
  }
}

.input-ikm-time-range-picker {
  @apply flex gap-2;

  & > div {
    @apply w-1/2;
  }

  .react-datepicker--time-only {
    .react-datepicker__time-container {
      width: 100%;
    }

    .react-datepicker-time__caption {
      display: none;
    }

    .react-datepicker-time__input-container input {
      width: 100% !important;
      text-align: center;
      font-weight: bold;
    }

    .react-datepicker__input-time-container {
      margin: 0;
    }

    .react-datepicker-time__input {
      margin: 0 10px !important;
    }

    .react-datepicker__time-list-item--selected {
      @apply !bg-interactive-default rounded;
    }

    .react-datepicker__time-list-item--disabled {
      @apply line-through;
    }

    .react-datepicker__time-list-item--selected.react-datepicker__time-list-item--disabled {
      @apply !bg-gray-50;
    }
  }
}

.certificate-input-number {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.certificate-table-profile {
  table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  table tr td {
    @apply border-r border-r-subdued;
    @apply border-b border-b-subdued;
  }

  table tr th:first-child,
  table tr td:first-child {
    @apply border-l border-l-subdued;
  }

  table tr:first-child td {
    @apply border-t border-t-subdued;
  }

  table tr td {
    background: #fff;
  }

  /* top-left border-radius */
  table tr:first-child td:first-child {
    @apply rounded-tl;
  }

  /* top-right border-radius */
  table tr:first-child td:last-child {
    @apply rounded-tr;
  }

  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    @apply rounded-bl;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    @apply rounded-br;
  }
}

/* MIL Contextualized Page Widget */
.widget-component > p:last-child {
  margin-bottom: 0;
}

.widget-component:not(.widget-component
    .widget-component, .widget-component--container, .widget-toolbox) {
  @apply px-3;
}

.widget-component:not(.widget-component
    .widget-component, .widget-component--container):last-child {
  @apply pb-4;
}

.widget-component.widget-component--container
  + .widget-component.widget-component--page-card {
  @apply -mt-1;
}

.widget-component.widget-component--container
  > .widget-component.widget-component--page-card:first-child {
  @apply mt-0;
}

.widget-toolbox {
  @apply transition-opacity cursor-move hover:opacity-80;
}

.widget-toolbox--disabled {
  @apply opacity-50 pointer-events-none grayscale-[100%];
}

div[data-widgetcomponent='ContentWrapper']
  > .widget-component.widget-component--page-card:first-child {
  @apply mt-4;
}

/* UJIKOM kinerja guru */

.ujikom-wysiwyg ol {
  list-style: decimal;
  padding: 20px;
}

.ujikom-data-tidak-sesuai-wysiwyg ol {
  list-style: decimal;
  padding-left: 16px;
}

.header-ujikom > div > section > div > div:nth-child(2) {
  color: #65686c;
}

.header-ujikom .one-line-action {
  @apply flex-auto;
}

.header-ujikom {
  max-width: 100% !important;
}

.header-ujikom > div > section:nth-child(2) {
  @apply grow-0;
}

.ujikom-panduan-wysiwyg ol {
  list-style: decimal;
  padding-left: 16px;
}

.ujikom-succes {
  color: #2e8a5c;
}

.certificate-page > canvas {
  max-width: 100% !important;
  height: 100% !important;
}

.max-w-ujikom {
  max-width: 792px;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.rpd-checkbox > :nth-child(3) {
  width: 100%;
}

.max-w-refkom {
  max-width: 732px;
}

.capitalize-words::first-letter {
  text-transform: capitalize;
}

.capitalize-words span::first-letter {
  text-transform: capitalize;
}

.min-h-refkom-dynamic {
  min-height: calc(100vh - 3.5rem) /* fallback for Opera, IE and etc. */;
  min-height: calc(100dvh - 3.5rem);
}

.min-h-kinerja {
  min-height: calc(100vh - 4.25rem) /* fallback for Opera, IE and etc. */;
  /* stylelint-disable-next-line unit-no-unknown */
  min-height: calc(100dvh - 4.25rem);
}

/* Animation, taken out from app/KinerjaKSPage/pages/Perencanaan/TabRangkuman/components/DialogFinalizeSKP/DialogFinalizeSKP.module.css */
@keyframes wiggle {
  25% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.wiggle {
  animation: wiggle 0.125s ease-in-out 4;
}
