/**
 * Do not edit directly
 * Generated on Fri, 07 Jun 2024 10:27:05 GMT
 */

[data-module="pengelolaan-kinerja"] {
  --size-rounded-0: 0rem;
  --size-rounded-2: 0.125rem;
  --size-rounded-4: 0.25rem;
  --size-rounded-8: 0.5rem;
  --size-rounded-12: 0.75rem;
  --size-rounded-16: 1rem;
  --size-rounded-24: 1.5rem;
  --size-rounded-full: 625rem;
  --size-border-radius-default: 0.25rem;
  --size-border-radius-base: 0.25rem;
  --size-border-radius-tab-selected: 0rem;
  --size-border-radius-button-lg: 0.25rem;
  --size-border-radius-button-md: 0.25rem;
  --size-border-radius-button-sm: 0.25rem;
  --size-font-helper: 0.75rem;
  --size-font-helper-bold: 0.75rem;
  --size-font-body-sm: 0.875rem;
  --size-font-body-small: 0.875rem;
  --size-font-body: 1rem;
  --size-font-body-bold: 1rem;
  --size-font-body-lg: 1.25rem;
  --size-font-body-large: 1.25rem;
  --size-font-body-lg-bold: 1.25rem;
  --size-font-action: 1rem;
  --size-font-action-secondary: 1rem;
  --size-font-subheading: 0.875rem;
  --size-font-heading-sm: 1.0625rem;
  --size-font-heading-md: 1.1875rem;
  --size-font-heading-lg: 1.375rem;
  --size-font-eyebrow-sm: 0.75rem;
  --size-font-eyebrow-md: 1rem;
  --size-font-eyebrow-lg: 1.25rem;
  --size-font-display-sm: 1.5rem;
  --size-font-display-md: 1.75rem;
  --size-font-display-lg: 2rem;
  --size-font-headline-xs: 2.625rem;
  --size-font-headline-sm: 3.375rem;
  --size-font-headline-md: 4rem;
  --size-font-headline-lg: 5rem;
  --size-line-height-text-helper: 1rem;
  --size-line-height-text-helper-bold: 1rem;
  --size-line-height-text-body-sm: 1.25rem;
  --size-line-height-text-body-small: 1.25rem;
  --size-line-height-text-body: 1.5rem;
  --size-line-height-text-body-bold: 1.5rem;
  --size-line-height-text-body-lg: 1.75rem;
  --size-line-height-text-body-large: 1.75rem;
  --size-line-height-text-body-lg-bold: 1.75rem;
  --size-line-height-text-action: 1.5rem;
  --size-line-height-text-action-secondary: 1.5rem;
  --size-line-height-text-subheading: 1rem;
  --size-line-height-text-heading-sm: 1.375rem;
  --size-line-height-text-heading-md: 1.5rem;
  --size-line-height-text-heading-lg: 1.75rem;
  --size-line-height-text-eyebrow-sm: 1rem;
  --size-line-height-text-eyebrow-md: 1.5rem;
  --size-line-height-text-eyebrow-lg: 1.5rem;
  --size-line-height-text-display-sm: 1.75rem;
  --size-line-height-text-display-md: 2.25rem;
  --size-line-height-text-display-lg: 2.25rem;
  --size-line-height-text-headline-xs: 3rem;
  --size-line-height-text-headline-sm: 3.875rem;
  --size-line-height-text-headline-md: 4.5rem;
  --size-line-height-text-headline-lg: 5.5rem;
}

/**
 * Do not edit directly
 * Generated on Fri, 07 Jun 2024 10:27:05 GMT
 */

[data-module="pengelolaan-kinerja"] {
  --font-family-inter: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-bossa: Bossa, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-jakarta: 'Plus Jakarta Sans Variable' ,'Plus Jakarta Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-helper: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-helper-bold: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-body-sm: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-body-small: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-body: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-body-bold: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-body-lg: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-body-large: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-body-lg-bold: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-action: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-action-secondary: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-subheading: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-heading-sm: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-heading-md: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-heading-lg: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-display-sm: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-display-md: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-display-lg: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-eyebrow-sm: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-eyebrow-md: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-eyebrow-lg: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-headline-xs: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-headline-sm: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-headline-md: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-family-headline-lg: 'Inter Variable', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-weight-helper: 400;
  --font-weight-helper-bold: 600;
  --font-weight-body-sm: 400;
  --font-weight-body-small: 400;
  --font-weight-body: 400;
  --font-weight-body-bold: 600;
  --font-weight-body-lg: 400;
  --font-weight-body-large: 400;
  --font-weight-body-lg-bold: 600;
  --font-weight-action: 600;
  --font-weight-action-secondary: 600;
  --font-weight-subheading: 600;
  --font-weight-heading-sm: 600;
  --font-weight-heading-md: 600;
  --font-weight-heading-lg: 600;
  --font-weight-eyebrow-sm: 400;
  --font-weight-eyebrow-md: 400;
  --font-weight-eyebrow-lg: 400;
  --font-weight-display-sm: 600;
  --font-weight-display-md: 600;
  --font-weight-display-lg: 600;
  --font-weight-headline-xs: 600;
  --font-weight-headline-sm: 600;
  --font-weight-headline-md: 600;
  --font-weight-headline-lg: 600;
}

/**
 * Do not edit directly
 * Generated on Fri, 07 Jun 2024 10:27:05 GMT
 */

[data-module="pengelolaan-kinerja"] {
  --elevation-00: 0;
  --elevation-01: 0px 1px 1px rgba(0, 0, 0, 0.08), 0px 2px 1px -1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(0, 0, 0, 0.1);
  --elevation-02: 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.1);
  --elevation-03: 0px 4px 5px rgba(0, 0, 0, 0.08), 0px 1px 10px rgba(0, 0, 0, 0.02), 0px 2px 4px -1px rgba(0, 0, 0, 0.1);
  --elevation-04: 0px 6px 10px rgba(0, 0, 0, 0.08), 0px 1px 18px rgba(0, 0, 0, 0.02), 0px 3px 5px -1px rgba(0, 0, 0, 0.1);
  --elevation-05: 0px 8px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 14px 2px rgba(0, 0, 0, 0.02), 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
  --elevation-06: 0px 12px 17px 2px rgba(0, 0, 0, 0.08), 0px 5px 22px 4px rgba(0, 0, 0, 0.02), 0px 7px 8px -4px rgba(0, 0, 0, 0.1);
  --elevation-07: 0px 16px 24px 2px rgba(0, 0, 0, 0.08), 0px 6px 30px 5px rgba(0, 0, 0, 0.02), 0px 8px 10px -5px rgba(0, 0, 0, 0.1);
  --elevation-08: 0px 24px 38px 3px rgba(0, 0, 0, 0.08), 0px 9px 46px 8px rgba(0, 0, 0, 0.02), 0px 11px 15px -7px rgba(0, 0, 0, 0.1);
}

/**
 * Do not edit directly
 * Generated on Fri, 07 Jun 2024 10:27:05 GMT
 */

[data-module="pengelolaan-kinerja"] {
  --component-badge-default-border-radius: 20px;
  --component-badge-default-padding: 2px 8px;
  --component-badge-default-font-weight: 600;
  --component-badge-default-font-size: 0.875rem;
  --component-badge-default-line-height: 1.25rem;
  --component-badge-md-padding: 2px 8px;
  --component-badge-success-background-color: #9ae5b9;
  --component-badge-success-color: #2f3031;
  --component-badge-success-border-color: #9ae5b9;
  --component-badge-warning-background-color: #ffdfa3;
  --component-badge-warning-color: #2f3031;
  --component-badge-warning-border-color: #ffdfa3;
  --component-badge-critical-background-color: #ffe9ea;
  --component-badge-critical-color: #2f3031;
  --component-badge-critical-border-color: #ffe9ea;
  --component-badge-informational-background-color: #98dafb;
  --component-badge-informational-color: #2f3031;
  --component-badge-informational-border-color: #98dafb;
  --component-badge-neutral-background-color: #ffffff;
  --component-badge-neutral-color: #2f3031;
  --component-badge-neutral-border-color: #c9cbcf;
  --component-badge-additional-background-color: #F2EFFC; /* Need to update */
  --component-badge-additional-color: #2f3031;
  --component-badge-additional-border-color: #F2EFFC; /* Need to update */
  --component-badge-expressive-success-background-color: #2e8a5c;
  --component-badge-expressive-success-color: #ffffff;
  --component-badge-expressive-success-border-color: #2e8a5c;
  --component-badge-expressive-warning-background-color: #eda62b;
  --component-badge-expressive-warning-color: #2f3031;
  --component-badge-expressive-warning-border-color: #eda62b;
  --component-badge-expressive-informational-background-color: #0b5fef;
  --component-badge-expressive-informational-color: #ffffff;
  --component-badge-expressive-informational-border-color: #0b5fef;
  --component-badge-expressive-critical-background-color: #e02d38;
  --component-badge-expressive-critical-color: #ffffff;
  --component-badge-expressive-critical-border-color: #e02d38;
  --component-badge-expressive-neutral-background-color: #53575a;
  --component-badge-expressive-neutral-color: #ffffff;
  --component-badge-expressive-neutral-border-color: #53575a;
  --component-badge-expressive-additional-background-color: #5C32D1; /* Need to update */
  --component-badge-expressive-additional-color: #ffffff;
  --component-badge-expressive-additional-border-color: #5C32D1; /* Need to update */
  --component-chip-default-color-border: #808489;
  --component-chip-default-font-weight: 600;
  --component-chip-hovered-color-background: #ecf5fe;
  --component-chip-hovered-color-text: #0b5fef;
  --component-chip-pressed-color-background: #cfe3fc;
  --component-chip-pressed-color-border: #054bcc;
  --component-chip-pressed-color-text: #054bcc;
  --component-chip-focused-color-border: #53575a;
  --component-collapsible-default-border-color: #c9cbcf;
  --component-collapsible-list-focused-border-radius: 0.25rem;
  --component-collapsible-card-default-border-radius: 0.25rem;
  --component-collapsible-card-hovered-border-color: transparent;
  --component-collapsible-card-opened-border-color: #c9cbcf;
  --component-collapsible-card-trigger-default-padding: 16px;
  --component-collapsible-card-trigger-default-font-weight: 600;
  --component-collapsible-card-trigger-default-font-size: 1.0625rem;
  --component-collapsible-card-trigger-disabled-background-color: #f5f5f5;
  --component-collapsible-card-trigger-disabled-text-color: #65696c;
  --component-collapsible-card-trigger-hovered-border-color: transparent;
  --component-collapsible-card-trigger-hovered-background-color: #f5f5f5;
  --component-collapsible-card-trigger-pressed-background-color: #efefef;
  --component-collapsible-card-trigger-opened-font-weight: 600;
  --component-collapsible-card-trigger-opened-border-bottom-color: #c9cbcf;
  --component-input-default-border-color: #808489;
  --component-pagination-default-font-weight: 600;
  --component-pagination-default-font-size: 1rem;
  --component-pagination-default-font-line-height: 1.5rem;
  --component-pagination-default-border-radius: 0.25rem;
  --component-pagination-selected-font-weight: 600;
  --component-pagination-selected-background-color: #efefef;
  --component-pagination-selected-color: #2f3031;
  --component-tabs-selected-font-weight: 600;
  --component-tabs-selected-font-color: #2f3031;
  --component-tabs-default-font-weight: 600;
  --component-tabs-default-font-color: #2f3031;
}

/**
 * Do not edit directly
 * Generated on Fri, 07 Jun 2024 10:27:05 GMT
 */

[data-module="pengelolaan-kinerja"] {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-gray-0: #fafafa;
  --color-gray-5: #f5f5f5;
  --color-gray-10: #efefef;
  --color-gray-20: #d9dbdd;
  --color-gray-30: #c9cbcf;
  --color-gray-40: #b3b5b7;
  --color-gray-50: #808489;
  --color-gray-60: #65696c;
  --color-gray-70: #53575a;
  --color-gray-80: #45474a;
  --color-gray-90: #2f3031;
  --color-gray-100: #1e1f1f;
  --color-red-0: #fffafa;
  --color-red-5: #fef0f0;
  --color-red-10: #ffe6e7;
  --color-red-20: #facccf;
  --color-red-30: #fbb7bc;
  --color-red-40: #f7979f;
  --color-red-50: #ed5362;
  --color-red-60: #e02d38;
  --color-red-70: #c82228;
  --color-red-80: #af182c;
  --color-red-90: #70111d;
  --color-red-100: #3e0f15;
  --color-pink-0: #fef6fa;
  --color-pink-5: #feecf4;
  --color-pink-10: #ffe1ef;
  --color-pink-20: #fbc6e0;
  --color-pink-30: #fab2d6;
  --color-pink-40: #f692c6;
  --color-pink-50: #ed4a97;
  --color-pink-60: #d4307a;
  --color-pink-70: #c41b63;
  --color-pink-80: #b60c50;
  --color-pink-90: #6e0d37;
  --color-pink-100: #3e0e0e;
  --color-purple-0: #fbfaff;
  --color-purple-5: #f4f0fe;
  --color-purple-10: #ebe6ff;
  --color-purple-20: #ddd3fd;
  --color-purple-30: #cfc1fb;
  --color-purple-40: #bba7f6;
  --color-purple-50: #906cef;
  --color-purple-60: #7a47f0;
  --color-purple-70: #6b2ff9;
  --color-purple-80: #5723cf;
  --color-purple-90: #382166;
  --color-purple-100: #1e0f3d;
  --color-blue-0: #f6fafe;
  --color-blue-5: #ecf5fe;
  --color-blue-10: #e1effe;
  --color-blue-20: #cfe3fc;
  --color-blue-30: #b1d1fb;
  --color-blue-40: #91bcfd;
  --color-blue-50: #4c8df6;
  --color-blue-60: #2c70e5;
  --color-blue-70: #0b5fef;
  --color-blue-80: #054bcc;
  --color-blue-90: #103178;
  --color-blue-100: #0e1c39;
  --color-cyan-0: #f6fcfe;
  --color-cyan-5: #e7f6fe;
  --color-cyan-10: #dcf3fe;
  --color-cyan-20: #c0e8fc;
  --color-cyan-30: #9ddcfb;
  --color-cyan-40: #74cbfb;
  --color-cyan-50: #3996ed;
  --color-cyan-60: #1b7ada;
  --color-cyan-70: #0468cc;
  --color-cyan-80: #125096;
  --color-cyan-90: #103763;
  --color-cyan-100: #082126;
  --color-teal-0: #f1fefe;
  --color-teal-5: #defcfb;
  --color-teal-10: #d2f9f8;
  --color-teal-20: #b9eeee;
  --color-teal-30: #98e5e7;
  --color-teal-40: #6dd5d4;
  --color-teal-50: #479ca1;
  --color-teal-60: #347f7a;
  --color-teal-70: #1e716b;
  --color-teal-80: #165a57;
  --color-teal-90: #0d4241;
  --color-teal-100: #092524;
  --color-green-0: #f1fef5;
  --color-green-5: #e3fdeb;
  --color-green-10: #cefde0;
  --color-green-20: #bcf1d1;
  --color-green-30: #a1e8be;
  --color-green-40: #7bd3a1;
  --color-green-50: #4ca375;
  --color-green-60: #2e8a5c;
  --color-green-70: #117e47;
  --color-green-80: #04673a;
  --color-green-90: #084a2d;
  --color-green-100: #092519;
  --color-orange-5: #fff9ee;
  --color-orange-10: #ffeece;
  --color-orange-20: #ffd78a;
  --color-orange-30: #fabf4b;
  --color-orange-40: #eda62b;
  --color-orange-50: #d99620;
  --color-orange-60: #b57f21;
  --color-orange-70: #976a1c;
  --color-orange-80: #815b18;
  --color-orange-90: #5f4212;
  --color-orange-100: #432c06;
  --color-yellow-5: #fffeee;
  --color-yellow-10: #fffdce;
  --color-yellow-20: #fff78a;
  --color-yellow-30: #faf44b;
  --color-yellow-40: #ede500;
  --color-yellow-50: #d7d004;
  --color-yellow-60: #beb804;
  --color-yellow-70: #a5a003;
  --color-yellow-80: #7d7903;
  --color-yellow-90: #646002;
  --color-yellow-100: #3a3900;
  --color-action-default: #0b5fef;
  --color-action-hovered: #054bcc;
  --color-action-pressed: #103178;
  --color-action-disabled: #c9cbcf;
  --color-action-neutral-default: #2f3031;
  --color-action-neutral-hovered: #1e1f1f;
  --color-action-neutral-pressed: #000000;
  --color-background-default: #fafafa;
  --color-background-hovered: #f9fafa; /* deprecated, will be removed */
  --color-background-pressed: #f9fafa; /* deprecated, will be removed */
  --color-background-disabled: #f9fafa; /* deprecated, will be removed */
  --color-background-expressive-dark-blue: #103178;
  --color-background-overlay-default: #000000;
  --color-primary: #0b5ae2;
  --color-critical: #c82236;
  --color-success: #35825a;
  --color-warning: #ffc453;
  --color-informational: #4c8df6;
  --color-border-high: #53575a;
  --color-border-default: #808489;
  --color-border-subdued: #c9cbcf;
  --color-border-disabled: #d9dbdd;
  --color-border-hovered: #45474a;
  --color-border-pressed: #2f3031;
  --color-border-critical-default: #e02d38;
  --color-border-critical-subdued: #909498; /* deprecated, will be removed */
  --color-border-critical-disabled: #909498; /* deprecated, will be removed */
  --color-border-success-default: #4ca375;
  --color-border-success-subdued: #909498; /* deprecated, will be removed */
  --color-border-success-disabled: #909498; /* deprecated, will be removed */
  --color-border-informational-default: #909498; /* deprecated, will be removed */
  --color-border-informational-subdued: #909498; /* deprecated, will be removed */
  --color-border-informational-disabled: #909498; /* deprecated, will be removed */
  --color-border-warning-default: #eda62b;
  --color-expressive-yellow: #eda62b;
  --color-expressive-green: #2e8a5c;
  --color-expressive-blue: #0b5fef;
  --color-expressive-pink: #d4307a;
  --color-icon-default: #45474a;
  --color-icon-subdued: #65696c;
  --color-icon-hovered: #2f3031;
  --color-icon-pressed: #1e1f1f;
  --color-icon-disabled: #b3b5b7;
  --color-icon-critical: #e02d38;
  --color-icon-warning: #b57f21;
  --color-icon-success: #4ca375;
  --color-icon-informational: #054bcc;
  --color-icon-inverse: #f5f5f5;
  --color-interactive-default: #0b5fef;
  --color-interactive-pressed: #2c7fff; /* deprecated, will be removed */
  --color-interactive-hovered: #054bcc;
  --color-interactive-disabled: #c9cbcf;
  --color-interactive-on-foreground: #91bcfd;
  --color-interactive-critical-default: #d93640;
  --color-interactive-critical-pressed: #2c7fff; /* deprecated, will be removed */
  --color-interactive-critical-hovered: #2c7fff; /* deprecated, will be removed */
  --color-interactive-focused: #4c8df6;
  --color-surface-default: #ffffff;
  --color-surface-subdued: #f5f5f5;
  --color-surface-hovered: #efefef;
  --color-surface-pressed: #d9dbdd;
  --color-surface-disabled: #efefef;
  --color-surface-foreground: #45474a; /* For snackbar */
  --color-surface-neutral-high: #efeff0; /* deprecated, will be removed */
  --color-surface-neutral-default: #d9dbdd;
  --color-surface-neutral-subdued: #efefef;
  --color-surface-critical-default: #ffe9ea;
  --color-surface-critical-subdued: #fef4f4;
  --color-surface-critical-hovered: #efeff0; /* deprecated, will be removed */
  --color-surface-critical-pressed: #efeff0; /* deprecated, will be removed */
  --color-surface-success-default: #9ae5b9;
  --color-surface-success-subdued: #e3fdeb;
  --color-surface-success-hovered: #efeff0; /* deprecated, will be removed */
  --color-surface-success-pressed: #efeff0; /* deprecated, will be removed */
  --color-surface-informational-default: #98dafb;
  --color-surface-informational-subdued: #e7f6fe;
  --color-surface-informational-hovered: #efeff0; /* deprecated, will be removed */
  --color-surface-informational-pressed: #efeff0; /* deprecated, will be removed */
  --color-surface-warning-default: #ffdfa3;
  --color-surface-warning-subdued: #ffeece;
  --color-surface-warning-hovered: #efeff0; /* deprecated, will be removed */
  --color-surface-warning-pressed: #efeff0; /* deprecated, will be removed */
  --color-surface-selected-default: #ecf5fe;
  --color-surface-selected-hovered: #e1effe;
  --color-surface-selected-pressed: #efeff0; /* deprecated, will be removed */
  --color-text-default: #2f3031;
  --color-text-subdued: #65696c;
  --color-text-disabled: #808489;
  --color-text-critical: #e02d38;
  --color-text-inverse: #ffffff;
  --color-text-inverse-subdued: #d9dbdd;
  --color-text-warning: #a5a003;
  --color-text-success: #2e8a5c;
  --color-text-informational: #4c8df6;
}
